.item {
	overflow: hidden;
}

.item:nth-child(3n+3) {
	margin-right: 0 !important;
}

.titleBox {
	height          : 44px;
	position        : absolute;
	top             : 65%;
	left            : 50%;
	transform: translateX(-50%);
}

.iconBox {
	position  : absolute;
	top       : 15%;
	left      : 40%;
	width     : 74px;
	height    : 74px;
	object-fit: contain;
}

.item:hover {
	background-color: rgba(51, 112, 255, 0.9) !important;
}

.item:hover .titleBox {
	top        : 40% !important;
	left       : 25px !important;
	font-size  : 32px;
	font-weight: 600;
	color      : #FFFFFF;
	transform: translateX(0%);
}

.item:hover .iconBox {
	width : 120px;
	height: 120px;
	top   : 34%;
	left  : 65%;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}