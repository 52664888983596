.jobs-info-top-body{
    display: flex;
    align-items: center;
    background: rgba(51,112,255,0.1);
    padding: 25px;
}
.jobs-info-top-left-body{
    margin-right: 20px;
}
.jobs-info-top-left-body img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.jobs-info-content-left-title{
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
}