.item-info-title{
    margin-top: 10px;
    font-size: 30px;
    font-weight: 500;
}
.item-info-content{
    border-bottom: 1px solid #DDDDDD;
    padding: 10px 0 20px 0;
}
.item-info-content-item{
    font-size: 18px;
    margin-top: 5px;
}
.item-info-type-body{
    display: flex;
    margin-bottom: 30px;
}
.item-info-type{
    padding: 10px 0 8px 0;
    font-size: 18px;
    margin-right: 40px;
    cursor: pointer;
}
.item-type-active{
    border-bottom: 4px solid #3370FF;
}