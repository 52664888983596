.contact-us-title{
    font-size: 20px;
    padding: 20px 0;
    font-weight: bold;
    border-bottom: 1px solid #EEEEEE;
}
.contact-us-item{
    display: flex;
    margin-top: 20px;
}
.contact-us-item-title{
    width: 160px;
    text-align: right;
    font-size: 18px;
    font-weight: 500;
    padding-right: 30px;
}
.contact-us-item-content{
    font-size: 16px;
}