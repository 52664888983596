.aboutLi {
	width: 100%;
	height: 76px;
	border-bottom: 1px solid #979797;
	padding: 0 30px 0 7px;
}

.aboutLi:hover div {
	color: #3370FF !important;
}

.aboutLi:hover img {
	background-color: #3370FF !important;
}

.h-y-list-body {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	min-height: 280px;
	padding: 20px;
}

.h-y-list-item-body {
	/* width: calc(100% / 6); */
	max-width: 220px;
	max-height: 100px;
	margin-bottom: 20px;
	border: 1px solid #eee;
}
.h-y-list-item-t{
	margin-top: 10px;
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	/*文本不换行*/
	white-space: nowrap;
	/*溢出部分隐藏*/
	overflow: hidden;
	/*溢出部分用"..."代替*/
	text-overflow: ellipsis;
}