.book-top-body {
    width: 100%;
    height: 600px;
    background-color: #D8D8D8;
    padding: 50px;
    display: flex;
    margin-top: 20px;
    /* align-items: center; */
}

.book-item-body {
    padding: 20px;
    background-color: #fff;
    display: flex;
}

#flipbook {
    flex: 1;
}

.book-title {
    font-size: 22px;
    font-weight: bold;
    padding: 20px 0;
}

.book-content-body {
    display: flex;
}

#magazine {
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#magazine .page {
    height: 100%;
}

#magazine .page img {
    max-width: 100%;
    height: 100%;
}
.page-body{
    transform-origin: left;
    transform-style:preserve-3d;
    position: absolute;  
    padding: 10px;      
    left: 50%;
    top: 0;
    width: 50%;
    height: 100%;
    border-left: 1px solid #eee;
    box-sizing: border-box;
    cursor: pointer;
    /* 元素背向观察者时，不可见 */
}
.page1{
    width: 100%;
    height: 100%;
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateZ(0px);
}
.book1{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.page-back{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transform: rotateY(180deg);
    transform: translateZ(-1px);
}
.page-z-index100{
    transition: 3s;
    z-index: 100;
}
.page-z-index90{
    transition: 3s;
    z-index: 90;
}
.page-z-index80{
    transition: 3s;
    z-index: 80;
}