.association-charter-title{
    margin: 20px 0;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
}

.management-team-body{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.management-team-left-item{
    margin-bottom: 20px;
    width: 160px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #EEEEEE;
    border-radius: 7px;
    font-size: 18px;
    font-weight: 400;
    cursor:pointer;
}
.management-team-left-item:hover{
    background-color: #3370FF;
    color: #fff;
}
.management-team-left-item-active{
    background-color: #3370FF;
    color: #fff;
}
.management-team-right-body{
    margin-left: 60px;
    flex: 1;
}
.management-team-right-pagination{
    padding-bottom: 20px;
}
.management-team-right-list{
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
    min-height: 650px;
}
.management-team-right-item{
    width: 224px;
    margin-bottom: 30px;
    margin-left: 0 ;
    border: 1px solid #EEEEEE;
    margin-left: 35px;
    padding: 1px;
    cursor: pointer;
}
.management-team-right-item-title{
    padding-top: 26px;
    text-align: center;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
}
.management-team-right-item-content{
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
}
.management-team-right-content{
    font-size: 16px;
    color: #333333;
    font-weight: 400px;
    margin-top: 10px;
}
.management-team-right-content-button{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.association-charter-body{
    display: flex;
    height: 100vh;
}
.association-charter-catalogue-body{
    width: 266px;
    font-size: 16px;
    overflow-x: auto;
    position:relative;
    border: 2px solid #EEEEEE;
    background: #FBFBFB;
    padding: 0 10px;
}
.association-charter-catalogue{
    font-size: 18px;
    font-weight: bold;
    padding-top: 25px;
}
.association-charter-content-catalogue-item{
    cursor:pointer;
    padding: 10px 0;
    border-bottom: 1px solid #EEEEEE;
}
.catalogue-item-active{
    color: #3370FF;
}
.association-charter-content{
    margin-bottom: 20px;
    min-height: 500px;
}
.association-charter-content img{
    max-width:  100%;
}
.load-more-button{
    width: 100%;
    position: absolute;
    padding: 10px;
    bottom: 0;
    text-align: center;
    cursor:pointer;
}
.load-more-button:hover{
    color: #3370FF;
}
.association-charter-content-body{
    flex: 1;
    padding-left: 20px;
}