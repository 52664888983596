.tab-type-body{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.tab-type-item{
    padding: 10px 17px;
    border-radius: 25px;
    font-size: 18px;
    font-weight: 400;
    margin: 0 5px;
    border: 1px solid #999999;
    cursor: pointer;
}
.tab-type-item-active{
    color: #FFFFFF;
    background: #3370FF;
    border: 1px solid #3370FF;
}
.tab-list-pag{
    padding: 20px 0;
}
.pad-40{
    padding: 40px 0;
}
.query-body{
    padding: 15px 20px;
    background-color: #F8F8F8;
}
.query-list-item{
    display: flex;
    padding: 6px 0px;
    
}
.query-list-item-active{
    border-bottom: 1px solid #D8D8D8;
}
.query-list-item-title{
    font-size: 18px;
    font-weight: 500;
    padding-top: 4px;
}
.query-list-item-type-body{
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.query-list-item-type{
    font-size: 16px;
    font-weight: 400;
    margin-left: 15px;
    padding: 4px 15px;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #D8D8D8;
    margin-top: 5px;
    margin-bottom: 5px;
}
.query-list-item-type-active{
    background-color: #3370FF;
    color: #FFFFFF;
}
.query-list-item-more{
    font-size: 14px;
    display: flex;
    padding-top: 10px;
    cursor: pointer;
    color: #999999;
}

.query-list-item-more-bottom {
    height: 10px;
    border: 10px solid transparent;
    border-top: 10px solid #999999;
    margin-left: 10px;
    margin-top: 8px;
}

.query-list-item-more-top {
    height: 10px;
    border: 10px solid transparent;
    border-bottom: 10px solid #999999;
    margin-left: 10px;
    margin-top: -3px;
}
.query-list-item-more:hover{
    color: #3370FF;
}
.query-list-item-more:hover .query-list-item-more-bottom{
    border-top: 10px solid #3370FF;
}
.query-list-item-more:hover .query-list-item-more-top{
    border-bottom: 10px solid #3370FF;
}
.query-list-item-type-body-no-select{
    height: 48px;
    overflow: hidden;
    box-sizing: border-box;
}
.query-input-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 5px;
}
.query-input{
    display: flex;
}
.query-input-text{
    min-width: 300px;
    background: #FFFFFF;
    padding: 0px 15px;
    border-left: 1px solid #979797;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    font-size: 18px;
}
.query-input-text:focus{
    outline:none;
    border-left: 1px solid #3370FF;
    border-top: 1px solid #3370FF;
    border-bottom: 1px solid #3370FF;
}
.query-input-button{
    padding: 8px 20px;
    background: #3370FF;
    color: #FFFFFF;
    font-size: 18px;
    cursor: pointer;
}
.list-body-1{
    display: flex;
}
.list-left-body{
    flex: 1;
}
.list-right-body{
    padding: 15px 0;
    margin: 20px 0;
    margin-left: 30px;
    width: 300px;
}