.industrial-parks-content-left-list-body{
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    min-height: 90vh;
}
.industrial-parks-content-left-list-item-body{
    margin-bottom: 20px;
    flex: 1;
    margin: 0px auto;
}
.content-left-list-item-left-body{
    width: 306px;
    padding: 10px;
    cursor: pointer;
}
.content-left-list-item-title{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5px;
}
.list-item-left-subtitle{
    margin-bottom: 5px;
    font-size: 16px;
    color: #666666;
    font-weight: 400;
    display: flex;
}
.list-item-left-subtitle-title{
    width: 75px;
}
.list-item-left-subtitle-content{
    width: calc(100% - 75px);
}
.industrial-parks-details-top-body{
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #eeee;
    padding: 35px 0;
}
.industrial-parks-details-top-left-body{
    width: 600px;
    height: 400px;
}
.industrial-parks-details-top-right-body{
    margin-left: 35px;
    flex: 1;
}
.industrial-parks-details-top-right-title{
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 60px;
    text-align: center;
}
.industrial-parks-details-top-right-subtitle{
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #666666;
    margin-bottom: 20px;
}
.industrial-parks-details-top-right-subtitle-title{
    width:90px;
    font-size: 20px;
}
.industrial-parks-details-top-right-subtitle-content{
    flex: 1;
    font-size: 18px;
}
.industrial-parks-details-content-left-title{
    font-size: 18px;
    font-weight: 600;
}
.industrial-parks-details-content-left-subtitle{
    font-size: 16px;
    margin-top: 10px;
    color: #333333;
}
.industrial-parks-details-content-body{
    margin-top: 25px;
    display: flex;
    align-items: flex-start;
}
.industrial-parks-details-content-flex{
    display: flex;
    flex-wrap: wrap;
}
.industrial-parks-details-content-flex-item{
    width: 165px;
    color: #666666;
    padding: 40px 60px;
}
.industrial-parks-details-content-right-body{
    margin-left: 30px;
    padding: 20px;
    width: 290px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
}
.industrial-parks-details-content-left-body{
    flex: 1;
}