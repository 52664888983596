.login_box {
	width: 921px;
	height: 576px;
	background-color: #fff;
	display: flex;
	align-items: center;
	padding: 0 30px;
}

.login_box-image {
	width: 489px;
	height: 450px;
}

.login-body {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-size: 100%;
	position: fixed;
}

.login-title {
	font-size: 21px;
	font-weight: 600;
	padding-bottom: 30px;
}

.login-right {
	height: 450px;
	margin-left: 55px;
	flex: 1;
}

.login-form .ant-input:focus, .ant-input-focused{
}
.text-link{
	color: #3370FF;
	cursor: pointer;
}
.forget-the-password{
	cursor: pointer;
	padding: 0 8px;
}