.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  overflow: hidden;
	margin-bottom: -20px;
}

.block_14 {
}

.box_43 {
}

.text-group_8 {
	text-align: center;
	width: 100%;
  height: 180px;
}

.text_70 {
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 28px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  line-height: 45px;
}

.text-wrapper_23 {
  height: 66px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: center;
  line-height: 33px;
  margin-top: 20px;
}

.text_71 {
  width: 720px;
  height: 66px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 33px;
}

.text_72 {
  width: 720px;
  height: 66px;
  overflow-wrap: break-word;
  color: rgba(250, 173, 20, 1);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 33px;
}

.paragraph_3 {
  width: 720px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 20px;
}

.text_73 {
  width: 720px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(250, 173, 20, 1);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 20px;
}

.text_74 {
  width: 720px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 20px;
}

.text_75 {
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  white-space: nowrap;
  line-height: 33px;
  margin: 10px 0 0 0;
}

.group_13 {
	padding-top: 29px;
}

.image_4 {
  width: 150px;
  height: 150px;
  margin-top: 110px;
}

.image_5 {
  width: 180px;
  height: 379px;
}

.block_11 {
  border-radius: 14px;
  background-image: url(https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/1293qassk1239123.png);
  width: 691px;
  height: 369px;
  background-size: 100%;
  border-bottom: 9px solid rgba(51, 51, 51, 1);
}

.text_76 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  font-family: PingFangSC-Medium;
  text-align: center;
  white-space: nowrap;
	margin: 40px 0 20px;
}

.section_5 {
  background-image: url(https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/128318230123.png);
	background-repeat: no-repeat;
	background-size: 100%;
  height: 792px;
	width: 100%;
}

.group_4 {
	padding: 0 88px 20px;
  background-color: rgba(243, 243, 243, 1);
  justify-content: flex-center;
}

.text-wrapper_31 {
	display: block;
  height: 45px;
	text-align: center;
  margin: 20px 0 ;
}

.text_77 {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 45px;
}

.box_44 {
	height: 400px;
	justify-content: space-between;
}

.text-wrapper_32 {
  width: 146px;
  height: 132px;
  margin-top: 104px;
}

.text_78 {
  width: 136px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 171, 243, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin-left: 10px;
}

.text_79 {
  width: 98px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 171, 243, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin: 24px 0 0 48px;
}

.text_80 {
  width: 146px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 171, 243, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin-top: 24px;
}

.block_15 {
  width: 182px;
  height: 199px;
  margin: 106px 0 0 15px;
}

.text_81 {
  width: 54px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.text_82 {
  width: 72px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 27px 0 0 2px;
}

.text-group_9 {
  width: 180px;
  height: 95px;
  margin: 27px 0 0 2px;
}

.text_83 {
  width: 130px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.text_84 {
  width: 126px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-top: 10px;
}

.text_85 {
  width: 180px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-top: 10px;
}

.grid_2 {
  width: 680px;
  height: 300px;
  margin-left: 0px;
  flex-wrap: wrap;
}

.list-items_1-0 {
  background-image: url(https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/weitu222.png);
	background-repeat: no-repeat;
	background-size: 100%;
  width: 327px;
  height: 189px;
  margin: 0 18px 18px 0;
}

.list-items_1-0:nth-child(2n) {
  margin-right: 0;
}
.list-items_1-0:nth-last-child(-n + 2) {
  margin-bottom: 0;
}

.list-items_1-1 {
  background-image: url(https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/weitu666.png);
  background-repeat: no-repeat;
	background-size: 100%;
  width: 327px;
  height: 189px;
  margin: 0 18px 18px 0;
}

.list-items_1-1:nth-child(2n) {
  margin-right: 0;
}
.list-items_1-1:nth-last-child(-n + 2) {
  margin-bottom: 0;
}

.list-items_1-2 {
  background-image: url(https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/weitu444.png);
  background-repeat: no-repeat;
	background-size: 100%;
  width: 327px;
  height: 189px;
  margin: 0 18px 18px 0;
}

.list-items_1-2:nth-child(2n) {
  margin-right: 0;
}
.list-items_1-2:nth-last-child(-n + 2) {
  margin-bottom: 0;
}

.list-items_1-3 {
  background-image: url(https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/weitu888.png);
  background-repeat: no-repeat;
	background-size: 100%;
  width: 327px;
  height: 189px;
  margin: 0 18px 18px 0;
}

.list-items_1-3:nth-child(2n) {
  margin-right: 0;
}
.list-items_1-3:nth-last-child(-n + 2) {
  margin-bottom: 0;
}

.box_45 {
  height: 400px;
	justify-content: space-between;
	margin-top: 50px;
}

.box_46 {
}

.box_39 {
  background-image: url(https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/weitu333.png);
  background-repeat: no-repeat;
	background-size: 100%;
  width: 327px;
  height: 189px;
}

.box_40 {
  background-image: url(https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/weitu2777.png);
	background-repeat: no-repeat;
	background-size: 100%;
  width: 327px;
  height: 189px;
  margin-top: 20px;
}

.box_47 {
  /* width: 374px; */
  margin-left: 20px;
}

.group_9 {
  background-image: url(https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/weitu555.png);
	background-repeat: no-repeat;
	background-size: 100%;
  width: 327px;
  height: 189px;
}

.group_10 {
  background-image: url(https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/weitu999.png);
  background-repeat: no-repeat;
	background-size: 100%;
  width: 327px;
  height: 189px;
  margin-top: 20px;
}

.text-wrapper_33 {
  width: 146px;
  height: 132px;
  margin: 103px 0 0 0;
}

.text_86 {
  width: 136px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 171, 243, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin-left: 10px;
}

.text_87 {
  width: 98px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 171, 243, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin: 24px 0 0 48px;
}

.text_88 {
  width: 146px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(0, 171, 243, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin-top: 24px;
}

.box_48 {
  width: 168px;
  height: 199px;
  margin: 105px 0 0 15px;
}

.text_89 {
  width: 72px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.text_90 {
  width: 90px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 27px 0 0 2px;
}

.text-group_10 {
  width: 166px;
  height: 95px;
  margin: 27px 0 0 2px;
}

.text_91 {
  width: 116px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.text_92 {
  width: 126px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-top: 10px;
}

.text_93 {
  width: 166px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-top: 10px;
}
