.box-type-body{
    display: flex;
    align-items: center;
    justify-content: center;
}
.box-type-item-body{
    padding: 10px 17px;
    border-radius: 25px;
    font-size: 18px;
    font-weight: 400;
    margin: 0 5px;
    border: 1px solid #999999;
    cursor: pointer;
}
.box-type-item-active-body{
    color: #FFFFFF;
    background: #3370FF;
    border: 1px solid #3370FF;
}
.box-query-body{
    /* margin-top: 20px; */
    display: flex;
    justify-content: space-between;
}
.box-content-body{
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
}
.box-content-left-body{
    flex: 1;
}
.box-content-left-list-body{
    display: flex;
    flex-wrap: wrap;
}
.box-content-right-body{
    width: 265px;
    margin-left: 20px;
    padding: 15px;
    border: 1px solid #EEEEEE;
}
.box-content-left-list-item{
    margin-bottom: 40px;
    cursor: pointer;
    flex: 1;
}
.box-content-left-list-item-body{
    display: flex;
    width: 473px;
    padding: 20px;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 2px 7px 0px rgba(143,143,143,0.22);
    height: 100%;
}
.list-item-title{
    margin-top: 10px;
    margin-bottom: 2px;
    padding: 0 10px;
    width: 280px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.list-item-tips {
    font-size: 16px;
    padding: 0 10px;
    width: 280px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.list-item-contnent {
    font-size: 16px;
    padding: 0 10px;
    width: 280px;
    color: #333;
}
.list-item-tag-body{
    background: #EEEEEE;
    border-radius: 5px;
    margin-left: 10px;
    padding: 5px 10px;
}
.list-item-budget{
    font-size: 18px;
    color: #E02020;
    font-weight: 500;
    margin-left: 10px;
}
.list-item-tag-body1{
    border-radius: 5px;
    border: 1px solid #3370FF;
    padding: 5px 10px;
    margin-left: 10px;
    color: #3370FF;
}
.list-item-bottom-body{
    font-size: 16px;
    padding: 10px;
    color: #999999;
}
.list-item-bottom-body1{
    font-size: 16px;
    padding: 10px;
    color: #E02020;
}
.box-content-right-title{
    font-size: 18px;
    font-weight: 600;
}
.my-issue{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
.post-demand{
    background: rgba(51,112,255,0.1);
    padding: 10px 18px;
    color: #3370FF;
    font-size: 16px;
}
.post-demand img ,
.release-resources img{
    width: 40px;
    height: 46px;
    margin-left: 10px;
}
.release-resources{
    background: rgba(51,255,251,0.1);
    color: #3370FF;
    font-size: 16px;
    padding: 10px 18px;
}
.list-item-bottom{
    margin-left: 10px;
    margin-top: 10px;
    color: #999999;
}
.details-info-top{
    padding: 35px 0;
    margin-bottom: 35px;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    align-items: center;
}
.details-info-top-right{
    margin-left: 40px;
}
.details-info-budget{
    color: #E02020;
    margin-left: 20px;
}
.details-info-content{
    font-size: 16px;
    color: #999999;
    margin-bottom: 5px;
}
.details-info-tag-body{
    border-radius: 5px;
    border: 1px solid #3370FF;
    padding: 5px 20px;
    color: #3370FF;
}
.text_nowrap_content {
    word-break: break-all;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}