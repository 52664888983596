.recruitment-hands-info-top-body{
    background: rgba(51,112,255,0.1);
    padding: 25px;
}
.info-top-top-body{
    display: flex;
    align-items: center;
}
.info-top-top-title{
    font-size: 20px;
    font-weight: 600;
}
.info-top-top-pay{
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #E02020;
}
.info-top-botton{
    display: flex;
    justify-content: space-between;
}
.info-top-botton-right{
    display: flex;
    width: 600px;
    flex-wrap: wrap;
}
.info-top-botton-left{
    font-size: 16px;
}
.info-top-tag{
    padding: 5px 15px;
    border-radius: 22px;
    border: 1px solid #3370FF;
    color: #3370FF;
    margin-bottom: 10px;
    margin-left: 20px;
}
.hiring-body{
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
}
.hiring-body img{
    width: 56px;
    border-radius: 50%;
    margin-right: 20px;
}
.recruitment-hands-info-content-body{
    display: flex;
}
.recruitment-hands-info-content-left-body{
    flex: 1;
}
.recruitment-hands-info-content-right-body{
    width: 265px;
    border: 1px solid #EEEEEE;
    padding: 20px;
}
.info-content-left-title{
    font-size: 18px;
    font-weight: 600;
    padding: 20px 0;
}
.info-content-right-title{
    font-size: 16px;
    font-weight: 600;
}
.company-info-body{
    display: flex;
    align-items: center;
}
.company-info-body{
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 16px;
    color: #666666;
}
.company-info-body img{
    width: 61px;
    margin-right: 20px;
}