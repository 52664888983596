.list-body{
    padding-top: 20px;
    display: grid;
    grid-column-gap: 33px;
    /* display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    column-count: 4;
    column-gap: 26px; */
    margin-top: 15px;
}
.list-body1{
    padding-top: 20px;
    margin-top: 15px;
}
.list-body2{
    padding: 20px;
    display: grid;
    grid-column-gap: 26px;
    /* display: flex;
    align-items: baseline; */
    margin-top: 15px;
    /* flex-wrap: wrap;
    justify-content: space-between; */
}