.card-body{
    background: #FFFFFF;
    max-width: 290px;
    box-shadow: 0px 1px 5px 0px rgba(177,177,177,0.5);
    margin-bottom: 27px;
}
.card-center{
    padding: 10px 15px;
}
.card-title{
    font-size: 21px;
    font-weight: 500;
    color: #333333;
}
.card-note{
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
}
.card-note-item{
    margin-top: 0px;
    width: 100%;
    word-break:break-all;
    /*文本不换行*/
	white-space: nowrap;
	/*溢出部分隐藏*/
	overflow: hidden;
	/*溢出部分用"..."代替*/
	text-overflow: ellipsis;
}
.card-button{
    display: flex;
    justify-content: center;
    color: #3370FF;
    font-size: 18px;
    cursor: pointer;
    margin-top: 5px;
}
.card-top{
    position: relative;
}
.card-tag{
    position: absolute;
}
.card-conter{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-conter-remark{
    font-weight: 400;
    color: #999999;
    font-size: 16px;
}
.card-conter-money{
    font-size: 16px;
    font-weight: 400;
    color: #FE8621;
}
.card-conter-person{
    font-size: 16px;
    font-weight: 400;
}
.m-t-5{
    margin-top: 5px;
}
.m-t-18{
    margin-top: 18px;
}
.m-t-30{
    margin-top: 30px;
}
.card-details-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.card-details-button{
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-left: 1px solid #ccc;
    cursor: pointer;
    margin-left: 30px;
}
.card-details-conter{
    flex: 1;
    height: 134px;
    position: relative;
}
.card-details-conter-content{
    margin-top: 5px;
    min-height: 76px;
}
.management-team-right-item1{
    width: 222px;
    margin-bottom: 20px;
    margin-left: 0 ;
    border: 1px solid #EEEEEE;
    display: flex;
    justify-content: center;
    cursor:pointer;
}
.card-details-conter-title{
    width: 827px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.card-details-conter-footer{
    position: absolute;
    bottom: 0;
}
.card-body-2{
    width: 100%;
    cursor: pointer;
    border: 1px solid #EEEEEE;
    margin-bottom: 20px;
}
.card-body-2-top-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}
.card-body-2-top-left-title{
    font-size: 18px;
    font-weight: 600;
}
.card-top-left-botton-body{
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.card-top-2-pay{
    font-size: 16px;
    color: #E02020;
}
.card-tag-2{
    min-width: 50px;
    text-align: center;
    margin-left: 10px;
    padding: 5px 10px;
    background: #F6F6F6;
    border-radius: 7px;
}
.card-body-2-top-right-body{
    display: flex;
    width: 300px;
}
.card-2-top-right-right-body{
    margin-left: 15px;
}
.card-botton-8{
    border-top: 1px solid #D8D8D8;
    padding: 15px;
    color: #999999;
}