.lineBox {
	height: 74px;
	border-right: 1px solid #979797;
}

@keyframes fadeIn {
	to {
		opacity: 1;
		transform: translateY(80%);
	}
}

.active {
	transform: scaleY(1.4) !important;
	display: block !important;
	/* animation: fadeIn 0.8s forwards !important; */
	transform: translateY(0%) !important;
}

.text-warp {
	word-wrap: break-word;
	word-break: normal;
}
.header-tab-item-number{
	font-size: 65px;
	color: #ddd;
	height: 100%;
	line-height: 100px;
	font-weight: 700;
	text-align: center;
}
.header-tab-item-title{
	width: 100%;
	padding-top: 10px;
	background: #fff;
	z-index: 20;
	display: flex;
	justify-content: center;
	font-size: 18px;
	position: absolute;
	bottom: 5px;
	cursor: pointer;
}
.header-tab-item-body{
	position: relative;
	flex: 1;
	height: 100%;
}
.header-tab-top-body{
	height: 100px;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
}