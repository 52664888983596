.home-tabs-body{
	padding-top: 30px;
}
.home-tabs-bottom-body{
	display: flex;
	justify-content: space-between;
	padding-top: 15px;
}
.home-tabs-left-body{
	flex: 1;
	padding-right: 30px;
}
.home-tabs-title-body{
	display: flex;
	align-items: center;
	height: 54px;
	width: 854px;
	padding-bottom: 15px;
	border-bottom: 1px solid #ccc;
}
.home-tabs-title{
	font-size: 18px;
	flex: 1;
	cursor: pointer;
	transition: all 0.2s;
	text-align: center;
	font-weight: 400;
	margin-right: 70px;
}
.home-tabs-title-active{
	color: #3370FF;
	height: 100%;
	font-size: 20px;
	padding-bottom: 5px;
	font-weight: 500;
	border-bottom:  4px solid #3370FF;
}
.home-tabs-title:hover {
	color: #3370FF;
	height: 100%;
	font-size: 20px;
	padding-bottom: 5px;
	font-weight: 500;
	border-bottom:  2px solid #3370FF;
}
.home-tabs-more{
	display: flex;
	align-items: center;
	cursor: pointer;
}
.home-tabs-more-circle{
	width: 4px;
	height: 4px;
	background: #000;
	border-radius: 2px;
	margin-right: 8px;
}
.home-tabs-right-body{
	height: 422px;
	width: 618px;
	background:  rgba(131, 128, 128, 0.1);
}
.hot-tabs-item-body{
	display: flex;
	align-items: center;
	margin-bottom: 38px;
}
.hot-tabs-item-type{
	color: #fff;
	padding: 10px;
	font-size: 18px;
	background: #3370FF;
	border-radius: 7px;
	margin-right: 16px;
}
.hot-tabs-item-title{
	font-size: 16px;
	width: 500px;
	font-weight: 500;
	font-weight: bold;
	padding-bottom: 5px;
}
