.right-list-body{
    width: 100%;
    padding: 20px;
    border: 1px solid #EEEEEE;
}
.right-list-title{
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
}
.entrance-body{
    background: rgba(51,112,255,0.1);
    padding: 15px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.entrance-body img{
    width: 40px;
}
.entrance-title{
    font-size: 14px;
    font-weight: 500;
    color: #3370FF;
    margin-left: 15px;
}