*{
	margin: 0;
	padding: 0;
}
ul,
li {
	list-style: none;
}
/* input{  
	background:none;  
	outline:none;  
	border:none;
	outline-style: none;
} */
/* 谷歌 */ 
/* input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}  */
/* 火狐 */ 
/* input{
-moz-appearance:textfield;
}
input:focus{   
	border:none;
} */
a {
	text-decoration: none; /* 去除默认的下划线 */
	outline: none;	/* 去除旧版浏览器的点击后的外虚线框 */
	color: #000;	/* 去除默认的颜色和点击后变化的颜色 */ 
}
html {
	zoom:1
}

body {
	min-width: 1260px;
	background-color: #fff;
}
/* @media screen and (min-width:1180px) and (max-width:1500px){
	html{
		zoom:0.8
	}
}
@media screen and (max-width:1179px){
	html{
		zoom:0.6
	}
} */
@media screen and (max-width:992px) {
	html {
		zoom:0.6
	}
}
@media screen and (min-width:992px) {
	html {
		zoom:0.6
	}
}
@media screen and (min-width:1100px) {
	html {
				zoom:0.6
	}
}
@media screen and (min-width:1200px) {
	html {
				zoom:0.7
	}
}
@media screen and (min-width:1400px) {
	html {
				zoom:0.8
	}
}
@media screen and (min-width:1500px) {
	html {
				zoom:0.9
	}
}
::-webkit-scrollbar { width: 0 !important }
.pointer {
	cursor: pointer;
}
.page_body {
 width: 1260px;
 margin: 0 auto;
}
.tal {
	text-align: left;
}

.tac {
	text-align: center;
}

.tar {
	text-align: right;
}

.line_text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.pos_r {
	position: relative;
}

.pos_f {
	position: fixed;
}

.pos_a {
	position: absolute;
}

.flex {
	display: flex;
}

.flexr-jsb {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.flexr-jsa {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.flexr-jsc {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.flexr-jfe {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.flexc-jsa {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.flexc-jsb {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.flexc-jsc {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.flexc-jce {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.flexc-jcc {
	display: flex;
	justify-content: center;
}
.flexc {
	display: flex;
	flex-direction: column;
}
.flexc-aic {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.flex-aic {
	align-items: center;
}

.flex-aife {
	align-items: flex-end;
}

.flex-ais {
	align-items: stretch;
}
.flex-jcc {
	justify-content: center;
}
.flex-asc {
	align-self: center;
}

.flex-fww {
	flex-wrap: wrap;
}

.flex-fitem {
	flex: 1;
}

.flex-gitem {
	flex-grow: 1;
}

.flexc-jsb-100 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

.color_fff {
	color: #ffffff;
}

.color_353535 {
	color: #353535;
}

.color_de1f26 {
	color: #DE1F26;
}

.color_b5b5b5 {
	color: #B5B5B5;
}

.color_494949 {
	color: #494949;
}
.color_606468 {
	color: #606468;
}
.color_888 {
	color: #888;
}
.color_10b53c {
	color: #10b53c;
}
.color_ff971b {
	color: #ff971b;
}
.color_5820e0 {
	color: #5820e0;
}
.color_999 {
	color: #999;
}

.color_555 {
	color: #555;
}

.color_ffd563 {
	color: #FFD563;
}

.color_fff_02 {
	color: rgba(255, 255, 255, 0.2);
}

.color_0003 {
	color: rgba(0, 0, 0, 0.03);
}

.color_ffaa00 {
	color: #FFAA00;
}

.color_f99339 {
	color: #F99339;
}

.color_f8ab67 {
	color: #F8AB67;
}

.color_ff3445 {
	color: #FF3445;
}
.color_fa6400 {
	color: #FA6400;
}
.color_333 {
	color: #333;
}

.color_ffe8b5 {
	color: #FFE8B5;
}

.color_f64230 {
	color: #F64230;
}

.color_252a33 {
	color: #252A33;
}

.color_616161 {
	color: #616161;
}

.color_6d7278 {
	color: #6D7278;
}

.color_b7bed0 {
	color: #B7BED0;
}
.color_ddd {
	color: #DDDDDD;
}
.color_a4450f {
	color: #A4450F;
}
.color_3370ff {
	color: #3370FF;
}
.color_ffdfa4 {
	color: #FFDFA4;
}

.color_f081b4 {
	color: #F081B4;
}

.color_666 {
	color: #666666;
}

.color_777 {
	color: #777777;
}

.color_2a5af2 {
	color: #2A5AF2;
}

.color_3c7fff {
	color: #3C7FFF;
}

.color_8a9099 {
	color: #8a9099;
}

.color_3b7bfc {
	color: #3b7bfc;
}

.color_a0a0a0 {
	color: #A0A0A0;
}

.color_646b8a {
	color: #646B8A;
}
.color_e02020 {
	color: #E02020;
}
.color_fff {
	color: #fff;
}

.bg_f6f8f9 {
	background-color: #F6F8F9;
}

.bg_de1f26 {
	background-color: #DE1F26;
}

.bg_ffefee {
	background-color: #FFEFEE;
}

.bgc_f5f7fa {
	background-color: #F5F7FA;
}

.bgc_3c7fff {
	background-color: #3c7fff;
}

.bgc_10b53c {
	background-color: #10B53C;
}

.bgc_fa6400 {
	background-color: #fa6400;
}
.bgc_3370ff {
	background-color: #3370FF;
}
.bgc_ff971b {
	background-color: #FF971B;
}

.bgc_5820e0 {
	background-color: #5820E0;
}
.bgc_b7bed0 {
	background-color: #B7BED0;
}
.bgc_e02020 {
	background-color: #E02020;
}

.bgc_3b7bfc {
	background-color: #3b7bfc;
}

.bg_f7f7f7 {
	background-color: #F7F7F7 !important;
}

/* FFC0CB */
.bgc_ffc0cb {
	background-color: rgba(255, 192, 203, 0.4);
}

.bgc_de1f26 {
	background-color: #DE1F26;
}
.bgc_d8d8d8 {
	background-color: #d8d8d8;
}
.bgc_ffdf88 {
	background-color: #FFDF88;
}

.bgc_f8faff {
	background-color: #F8FAFF;
}

.bgc_fff {
	background-color: #fff;
}

.font_bold {
	font-weight: bold;
}

.font_500 {
	font-weight: 500;
}
.font_88 {
	font-size: 88px;
}
.font_48 {
	font-size: 48px;
}

.font_46 {
	font-size: 46px;
}

.font_44 {
	font-size: 44px;
}

.font_42 {
	font-size: 42px;
}

.font_40 {
	font-size: 40px;
}

.font_38 {
	font-size: 38px;
}

.font_36 {
	font-size: 36px;
}

.font_34 {
	font-size: 34px;
}

.font_32 {
	font-size: 32px;
}

.font_30 {
	font-size: 30px;
}

.font_28 {
	font-size: 28px;
}

.font_26 {
	font-size: 26px;
}

.font_24 {
	font-size: 24px;
}

.font_22 {
	font-size: 22px;
}
.font_21 {
	font-size: 21px;
}
.font_20 {
	font-size: 20px;
}
.font_19 {
	font-size: 19px;
}
.font_18 {
	font-size: 18px;
}
.font_17 {
	font-size: 17px;
}
.font_16 {
	font-size: 16px;
}

.font_14 {
	font-size: 14px;
}

.font_13 {
	font-size: 13px;
}

.font_12 {
	font-size: 12px;
}

.font_11 {
	font-size: 11px;
}

.font_10 {
	font-size: 10px;
}

.m_b_8 {
	margin-bottom: 8px;
}
.boxs_bb {
	box-sizing: border-box;
}
.transition_all {
	transition: all 0.2s;
}
/* 不换行 */
.text_nowrap {
	/*文本不换行*/
	white-space: nowrap;
	/*溢出部分隐藏*/
	overflow: hidden;
	/*溢出部分用"..."代替*/
	text-overflow: ellipsis;
}
.text_nowrap_2 {
	word-break: break-all;
	text-overflow: ellipsis;
	display: -webkit-box; 
	-webkit-box-orient: vertical; 
	-webkit-line-clamp: 2;
	overflow: hidden;
}
.text_nowrap_3 {
	word-break: break-all;
	text-overflow: ellipsis;
	display: -webkit-box; 
	-webkit-box-orient: vertical; 
	-webkit-line-clamp: 3;
	overflow: hidden;
}
.text_nowrap_5 {
	word-break: break-all;
	text-overflow: ellipsis;
	display: -webkit-box; 
	-webkit-box-orient: vertical; 
	-webkit-line-clamp: 5;
	overflow: hidden;
}
.pad-tb-10{
    padding: 10px 0;
}
.pad-tb-20{
    padding: 20px 0;
}
.min-h-1{
	min-height: 100vh;
}
.pad-tb-40{
    padding: 40px 0;
}
img{
	max-width: 100%;
}
.grid-3{
	grid-template-columns: max-content max-content max-content;
}
.grid-4{
	grid-template-columns: max-content max-content max-content max-content ;
}
.grid-5{
	grid-template-columns: max-content max-content max-content max-content max-content;
}