.my-info-body{
    padding: 40px;
    display: flex;
    align-items: flex-start;
}
.my-info-left-body{
    border: 1px solid #999999;
    border-radius: 7px;
    padding: 25px 20px;
    width: 255px;
}
.my-info-left-item{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}
.my-info-buddha-img{
    width: 72px;
    height: 72px;
    border-radius: 50%;
}
.my-info-right-body{
    margin-left: 30px;
    flex: 1;
}
.my-info-tabs{
    display: flex;
    align-items: center;
}
.my-info-tabs-item{
    margin-right: 50px;
    font-size: 18px;
    cursor: pointer;
}
.my-info-tabs-item-active{
    width: 100%;
    background: #3370FF;
    border-radius: 23px;
}
.my-info-right-content-bofy{
    width: 100%;
    border: 1px solid #999999;
    border-radius: 7px;
    padding: 20px;
    margin-top: 30px;
}
.account-information-title{
    padding-bottom: 18px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #EEEEEE;
}
.account-information-item-body{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.account-information-item-title{
    flex: 12.5%;
    text-align: right;
    max-width: 12.5%;
    font-size: 18px;
}
.account-information-item-conter{
    color: #666666;
    font-size: 16px;
}
.account-information-item-button{
    margin-left: 20px;
    font-size: 16px;
    color: #3370FF;
    cursor: pointer;
}
.excel-list-li-item-operation{
    padding-right: 10px;
    cursor: pointer;
    color: #3370FF;
}
.excel-list-li-item-operation-1{
    padding: 0 10px;
    border-left: 1px solid #EEEEEE ;
    cursor: pointer;
    color: #3370FF;
}
.excel-list-li-item1{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: 200px;
    border-left: 1px solid #979797;
    border-bottom: 1px solid #979797;
    padding: 20px;
    min-height: 65px;
}
.excel-top-title1{
    flex: 1;
    text-align: center;
    min-width: 200px;
}
.post-message-type{
    display: flex;
    align-items: center;
    border-bottom:  1px solid #3370FF;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: bold;
}
.post-message-type div{
    padding: 6px 10px;
    cursor: pointer;
    border-top:  1px solid #FFFFFF;
}
.post-message-type .active{
    border-radius: 5px 5px 0 0 ;
    border-top:  1px solid #3370FF;
    border-left:  1px solid #3370FF;
    border-right:  1px solid #3370FF;
    color:#3370FF;
}
.my-info-left-bottom{
    margin-top: 20px;
    border-top: 1px solid #EEEEEE;
}
.my-info-left-bottom-title{
    margin-top: 20px;
    font-weight: bold;
}
.my-info-left-bottom-conter{
    margin-top: 20px;
    color: #999999;
    font-size: 12px;
}