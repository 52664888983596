.Join-Us-button{
    background: #3370FF;
    border-radius: 4px;
    padding: 10px;
    width: 140px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}
.Join-Us-top{
    padding: 30px 0;
    border-bottom: 1px solid #EEEEEE;
}
.Join-Us-top-title{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 10px;
}
.Join-Us-submit-botton{
    width: 140px;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    color: #3370FF;
    border: 1px solid #3370FF;
    cursor: pointer;
    height: 46px;
}