.excel-title{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.excel-body{
    margin-top: 20px;
}
.excel-top-body{
    display: flex;
    align-items: center;
    background: rgba(51, 112, 255, 0.1);
}
.excel-top-title{
    flex: 1;
    text-align: center;
    padding: 20px 0;
    font-size: 16px;
}
.excel-list-body{
    width: 100%;
}
.excel-list-li{
    width: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid #979797;
}
.excel-list-li-item{
    flex: 1;
    font-size: 14px;
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid #979797;
    border-left: 1px solid #979797;
}