.Inquiry-body{
    width: 814px;
    background: linear-gradient(180deg, rgba(51,112,255,0.1) 0%, rgba(255,255,255,0.1) 100%);
    border: 1px solid rgba(151,151,151,0.1);
    margin: 40px auto;
    padding: 40px 180px;
}
.inquiry-title{
    font-size: 24px;
    text-align: center;
}
.inquiry-content{
    margin-top: 30px;
}
.inquiry-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ant-form-large .ant-form-item-label > label{
    font-size: 18px;
}